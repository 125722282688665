export class EventConstants {
  static GIFT_EVENT_TYPE = 1;
  static CHARGE_EVENT_TYPE = 2;
  static PAYMENT_EVENT_TYPE = 3;
  static CHARGE_GIFT_CARD_EVENT_TYPE = 4;
  static PAYMENT_GIFT_CARD_EVENT_TYPE = 5;
  static EXPIRATION_MONTH_COUNT = 60;
  static SEARCH_KEY_MIN_LENGTH = 1;
  static GIFT_CARD_MAX_LENGTH = 20;
  static ACTIVITIES_MAX_LENGTH = 3;
  static SHOP_FORM_TYPE = {
    common: { value: 1, label: '共通タイプ'},
    toyota: { value: 2, label: 'CSVタイプ' },
  };
  static status = {
    unregistered: { value: 0, label: '未登録' },
    preparingPublic: { value: 1, label: '公開準備中'},
    open: { value: 2, label: '公開中' },
    expired: { value: 3, label: '期間終了' },
    achieved: { value: 4, label: '予算達成' },
    paused: { value: 5, label: '一時停止' },
  };
  static creditCards = {
    visa: { label: 'Visa', value: 'VISA' },
    master: { label: 'MasterCard', value: 'MASTER' },
    jcb: { label: 'JCB', value: 'JCB' },
    amex: { label: 'AMEX', value: 'AMEX' },
    diners: { label: 'Diners', value: 'DINERS' },
  };
  static value3ds = {
    no: { label: '3DSなし', value: 0 },
    use: { label: '3DS2.0あり', value: 2 },
  };
  static expirationType = {
    dynamic: { label: '変動型', value: 1 },
    static: { label: '固定型', value: 2 },
  };
  static COUPON_FORM_TYPE = {
    common: { value: 0, label: '全体配布タイプ' },
    own: { value: 1, label: '個別配布タイプ' },
  };
  static roleName = {
    store: { value: 0, label: '店舗名' },
    office: { value: 1, label: '事務局' },
  };
  static sendNotificationType = {
    immediately: { label: '即時', value: 1 },
    scheduling: { label: '予約', value: 2 },
  };
  static partnerIdentityType = {
    civilios: { label: 'CIVILIOS', value: 1 },
    xid: { label: 'xID', value: 2 },
    digital: { label: 'デジタル庁', value: 3 },
  };
  static momentIdentityType = {
    no: { label: 'なし（会員登録時、本人確認なし）', value: 0 },
    any: { label: '任意（会員登録時、本人確認スキップ可）', value: 1 },
    have: { label: '必須（会員登録時、本人確認必須）', value: 2 },
  };
  static passcodeType = {
    default: { label: '必須', value: 0 },
    setting: { label: '任意（マイページにてON/OFF可能）', value: 1 },
  };
  static verificationType = {
    no: { label: '認証無し', value: 0 },
    identity: { label: '本人確認必須', value: 1 },
    sms: { label: 'SMS認証必須', value: 2 },
    both: { label: '本人確認＆SMS認証必須', value: 3 },
  };
  static expirationDateType = {
    default: { label: '管理画面からの金額発行許可の有効期限を利用する', value: 0 },
    setting: { label: '以下の有効期限を適用する', value: 1 },
  };
  static cpmTransferType = {
    default: { label: 'なし（通常通りの運用になります）', value: 0 },
    setting: { label: 'あり（CPM顧客が通常顧客に変更可能となります）', value: 1 },
  };
  static smsPhoneType = {
    none: { label: 'なし（SMS会員登録、携帯電話番号ログインなし）', value: 0 },
    yes: { label: 'あり（SMS会員登録任意、メール・携帯電話番号ログインいずれもあり）', value: 1 },
    email: { label: '会員登録必須（SMS会員登録必須、メール・携帯電話番号ログインいずれもあり）', value: 2 },
    phone: { label: '会員登録ログイン必須（SMS会員登録必須、携帯電話番号ログインのみ）', value: 3 },
  };
  static useChargeSmsType = {
    noCharge: { label: '変更しない（通常通り支払用パスコード、又は生体認証を利用します）', value: 0 },
    sms: { label: 'SMS認証を利用する（決済毎にSMS認証を行います）', value: 1 },
  };
  static digitalAgencyType = {
    production: { label: '本番環境', value: 1 },
    test: { label: 'テスト環境', value: 2 },
  };
}
